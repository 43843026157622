import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';


function TrenitaliastudentPage(props) {
    var myRet;

    useEffect(() => {
    }, []);



    if (!props.apiAuthName)
        myRet = null;
    else {
        myRet = (
            <React.Fragment>
                <h2 className="title-page">Trenitalia Welfare Plus 20%</h2>
                <br />
                <p>Nell’ambito del Programma Trenitalia for Business
                    Trenitalia ha il piacere di riservare
                    agli Studenti iscritti all’Università degli Studi di Milano-Bicocca
                    l’offerta Welfare PLUS, uno speciale sconto
                    valido per l’acquisto di biglietti
                    per viaggi privati dello Studente
                    con un massimo di 6 accompagnatori.
                    Lo sconto si applica ai biglietti emessi in tariffa
                    Base, Economy e Super Economy
                    di tutti i treni del servizio nazionale Frecce e Intercity.
                    Per usufruire dello sconto accedi all’area riservata.</p>
                
                <a href="./trenitalia/trenitalia_welfare_plus.pdf">Brochure Trenitalia Welfare Plus</a>

                <br /><br /><br />

                <Button href={process.env.REACT_APP_URL_TRENITALIASTUDENT}>
                    Area riservata per accedere allo sconto</Button>{' '}


            </React.Fragment >
        );

    }

    return myRet;
}

export default TrenitaliastudentPage;
